import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {

  selectedLangListener: Subject<number> = new Subject();

  selectedLang: number = 0;
  
  constructor(private http: HttpClient) {}

  public saveRequest(request: any): void {
    window.localStorage.removeItem("request");
    window.localStorage.setItem("request", JSON.stringify(request));
  }


  public getRequest(): any {
      return JSON.parse(window.localStorage.getItem("request") || 'null');
  }

  public saveOldRequest(oldRequest: any): void {
    window.localStorage.removeItem("oldRequest");
    window.localStorage.setItem("oldRequest", JSON.stringify(oldRequest));
  }

  public getOldRequest(): any {
    return JSON.parse(window.localStorage.getItem("oldRequest") || 'null');
}

  public clearOldRequest(): void {
    window.localStorage.removeItem("oldRequest");
  }

  public clearAllData(): void {
    window.localStorage.removeItem("oldRequest");
    window.localStorage.removeItem("request");
  }
}
