export class MessageEn {
    static ERR_TITLE = "Error";
    static SUCCESS_TITLE = "Success";
    static SERVER_ERRR = "Server error";

    static MSG_053 = " must be in a valid email address format.";
    static MSG_056 = " is required.";
    static MSG_016 = " can only contain alphanumeric characters.";
    static MSG_080_A01 = "Baggage details have not been entered.";
    static MSG_080_A03 = "The maximum number of applications for the day of use has been reached. We are sorry, but we are unable to accept reservations.";
    static MSG_068 = "There is no corresponding data.";
    static MSG_073 = "Are you sure you want to cancel this shipping request?";

    static MSG_099 = "The payment process will be terminated because the payment amount has exceeded. Please try the operation again from the beginning.";

    static OK = "OK";
    static CANCEL = "Cancel";
}