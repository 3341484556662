import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { every, take } from 'rxjs';
import { AppService } from 'src/app/service/app.service';

@Component({
  selector: 'app-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss']
})
export class LanguageDropdownComponent implements OnInit {
  @Input() options: any[] = [];

  selectedLang = {value : 0, name: '日本語'};

  listValue = [
    {value : 0, name: '日本語'},
    {value : 1, name: 'English'},
    {value : 2, name: '한국인'},
    {value : 3, name: '中文 (简体)'},
    {value : 4, name: '中文 (繁體)'}
  ]

  constructor(private translate: TranslateService, private appService: AppService) {

  }
  ngOnInit(): void {
    this.appService.selectedLang = this.selectedLang.value;
    this.defaultLangChange();
  }

  langChange() {
    this.appService.selectedLang = this.selectedLang.value;
    switch(this.selectedLang?.value) {
      case 0: 
        this.translate.use('ja');
        break;
      case 1: 
        this.translate.use('en');
        break;
      case 2: 
        this.translate.use('ko');
        break;
      case 3: 
        this.translate.use('cn');
        break;
      case 4: 
        this.translate.use('tw');
        break;
    }
    // emit lang change event by user
    this.appService.selectedLangListener.next(this.selectedLang.value);
  }

  defaultLangChange() {
    this.appService.selectedLangListener.subscribe({
      next: (res) => {
        // console.log("change def")
        this.selectedLang = this.listValue.find(x => x.value == res) || {value : 0, name: '日本語'};
        this.appService.selectedLang = this.selectedLang.value;
        // emit lang change event by default
        switch(res) {
          case 0: 
            this.translate.use('ja');
            break;
          case 1: 
            this.translate.use('en');
            break;
          case 2: 
            this.translate.use('ko');
            break;
          case 3: 
            this.translate.use('cn');
            break;
          case 4: 
            this.translate.use('tw');
            break;
        }
        
      }
    });
  }
}
