import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerService } from './spinner.service';

const TOKEN_KEY = 'auth-token';
const USER_NAME = 'user';
const LANGUAGE = 'la';
@Injectable({
    providedIn: 'root',
})
export class TokenStorageService {
    sub = new EventEmitter();

    constructor(
        private router: Router,
        private spinnerService: SpinnerService
    ) { }

    public saveToken(token: string): void {
        window.localStorage.removeItem(TOKEN_KEY);
        window.localStorage.setItem(TOKEN_KEY, token);
    }

    public saveUserName(userName: any): void {
        window.localStorage.removeItem(USER_NAME);
        window.localStorage.setItem(USER_NAME, userName);
    }


    public getUserName(): string | null {
        return window.localStorage.getItem(USER_NAME);
    }

    public getToken(): string | null {
        return window.localStorage.getItem(TOKEN_KEY);
    }

    public clearAll() {
        window.localStorage.clear();
    }

    public removeItem() {
        window.localStorage.removeItem(TOKEN_KEY);
        window.localStorage.removeItem(USER_NAME);
    }

    returnLogin() {
        this.router.navigate(['/login']);
    }

    public setLanguage(language: string): any {
        window.localStorage.removeItem(LANGUAGE);
        window.localStorage.setItem(LANGUAGE, language);
    }

    public getLanguage(): any {
        const language = window.localStorage.getItem(LANGUAGE);
        if (language) {
            return language;
        }
        return null;
    }

    public setLanguageDropdown(data: any) {
        this.sub.emit(data);
    }
}
