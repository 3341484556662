<div class="lang">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
        <path d="M1.25 8C1.25 11.4517 4.04822 14.25 7.5 14.25C10.9517 14.25 13.75 11.4517 13.75 8C13.75 4.54822 10.9517 1.75 7.5 1.75C4.04822 1.75 1.25 4.54822 1.25 8Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.125 1.78082C8.125 1.78082 10 4.24996 10 7.99994C10 11.7499 8.125 14.2191 8.125 14.2191" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.875 14.2191C6.875 14.2191 5 11.7499 5 7.99994C5 4.24996 6.875 1.78082 6.875 1.78082" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1.64355 10.1875H13.3565" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1.64355 5.8125H13.3565" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    &nbsp;Language &nbsp; <p-dropdown (onChange)="langChange()" [options]="options" optionLabel="name" [(ngModel)]="selectedLang" scrollHeight="200px"></p-dropdown>
</div>
