import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GmoPaymentService {
  constructor(private http: HttpClient) {}

  getNewPaymentUrl(id: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/gmo/new-payment`, { params: { id: id } });
  }

  repayment(req: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/gmo/repayment`, req, environment.httpOptions);
  }

  cancelPayment(req: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/gmo/cancel-payment`, req, environment.httpOptions);
  }
}
