import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/service/app.service';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss']
})
export class ThanksComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService) {
  }
  ngOnInit(): void {
    const paramObj: any = this.route.snapshot.queryParams;
    const lang = paramObj.hl;
    const currentLang = this.getSelectedLang(lang);
    this.appService.selectedLangListener.next(currentLang);
  }

  goToTop() {
    this.router.navigate(['carry-reserve']);
  }

  getSelectedLang(lang: string): number {
      switch (lang) {
        case "ja": return 0;
        case "en": return 1;
        case "ko": return 2;
        case "cn": return 3;
        case "tw": return 4;
        default: return 0;
    }
  }
}
