<section class="container">
    <div class="content">
        <h3>{{ "policy.site" | translate }}</h3>
        <p class="head title">{{ "policy.txt2" | translate }}</p>
        <p class="head" style="white-space: pre-wrap;">{{ "policy.txt3" | translate }}</p>
        <table class="mobile">
            <tr>
                <th>{{ "policy.txt4" | translate }}</th>
            </tr>
            <tr>
                <td>{{ "policy.txt5" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "policy.txt6" | translate }}</th>
            </tr>
            <tr>
                <td>{{ joinText("policy.txt7" | translate) }}</td>
            </tr>
            <tr>
                <th>{{ "policy.txt8" | translate }}</th>
            </tr>
            <tr>
                <td>{{ joinText("policy.txt9" | translate) }}</td>
            </tr>
            <tr>
                <th>{{ "policy.txt10" | translate }}</th>
            </tr>
            <tr>
                <td>{{ "policy.txt11" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "policy.txt12" | translate }}</th>
            </tr>
            <tr>
                <td>{{ "policy.txt13" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "policy.txt14" | translate }}</th>
            </tr>
            <tr>
                <td>{{ joinText("policy.txt15" | translate) }}</td>
            </tr>
        </table>
        <table class="pc">
            <tr>
                <th>{{ "policy.txt4" | translate }}</th>
                <td>{{ "policy.txt5" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "policy.txt6" | translate }}</th>
                <td>{{ joinText("policy.txt7" | translate) }}</td>
            </tr>
            <tr>
                <th>{{ "policy.txt8" | translate }}</th>
                <td>{{ joinText("policy.txt9" | translate) }}</td>
            </tr>
            <tr>
                <th>{{ "policy.txt10" | translate }}</th>
                <td>{{ "policy.txt11" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "policy.txt12" | translate }}</th>
                <td>{{ "policy.txt13" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "policy.txt14" | translate }}</th>
                <td>{{ joinText("policy.txt15" | translate) }}</td>
            </tr>
        </table>
        <p class="last">{{ "policy.txt16" | translate }}</p>
        <div style="display: flex; justify-content: center;">
            <button (click)="goToTop()" pButton class="p-button-warning btn-confirm btn-custom">
                {{ "preview.top" | translate }}
            </button>
        </div>
    </div>
</section>