<div class="container">
  <p *ngIf="param != 'update'" class="confirm-text">{{ "confirm.notYetComplete" | translate }}</p>
  <p>{{ "regist.cstInfo" | translate }}</p>
  <section>
    <div class="input-area">
      <div class="form-info">
        <div>
          <p *ngIf="data?.cancelFlag == 1" class="p-error warning">{{ "confirm.mes9" | translate }}</p>
          <p *ngIf="data?.shippingAcceptedStatus == 1 && data?.cancelFlag != 1" class="p-error warning">{{ "confirm.mes8" | translate }}</p>
          <p *ngIf="data?.cancelFlag != 1 && data?.shippingAcceptedStatus != 1 
          && (data?.payStatus == 0 || checkChangePrice())" 
          class="p-error warning">{{ "confirm.mes7" | translate }}</p>
        </div>
        <div class="item-info" [style]="param == 'update' ? {display: 'inherit'} : {display: 'none'}">
          <p>{{ "confirm.shippingNumber" | translate }}</p>
          <div class="barcode">
            <svg id="barcode"></svg>
          </div>
        </div>
        <div class="item-info">
          <p>{{ "regist.hotelName" | translate }}</p>
          <p class="content">{{data?.stayFacilityName}}</p>
        </div>
        <div class="item-info">
          <p>{{ "regist.hotelReservationName" | translate }}</p>
          <p class="content">{{data?.reserverName}}</p>
        </div>
        <div class="item-info">
          <p>{{ "regist.dateOfUse" | translate }}</p>
          <p class="content">{{data?.usageDate}}</p>
        </div>
        <div class="item-info">
          <p>{{ "regist.tel" | translate }}</p>
          <p class="content">{{data?.tel}}</p>
        </div>
        <div class="item-info">
          <p>{{ "regist.email" | translate }}</p>
          <p class="content">{{data?.email}}</p>
        </div>
      </div>

      <section class="baggage-info">
        <p class="baggage">{{ "regist.baggage" | translate }}</p>
        <div *ngFor="let item of data.shipDetail">
          <div class="baggage-item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="66"
              height="66"
              viewBox="0 0 66 66"
              fill="none"
            >
              <path
                d="M7.41235 19.7597L32.1624 30.7598L33.8377 26.9903L9.08765 15.9903L7.41235 19.7597ZM35.0625 59.125V28.875H30.9375V59.125H35.0625ZM33.8377 30.7598L58.5877 19.7597L56.9124 15.9903L32.1624 26.9903L33.8377 30.7598Z"
                fill="#490E00"
              />
              <path
                d="M8.25 47.0528V18.9473C8.25 18.2952 8.63401 17.7043 9.22988 17.4395L32.3298 7.17283C32.7566 6.98321 33.2434 6.98321 33.6702 7.17283L56.7702 17.4395C57.3661 17.7043 57.75 18.2952 57.75 18.9473V47.0528C57.75 47.7048 57.3661 48.2958 56.7702 48.5606L33.6702 58.8272C33.2434 59.0167 32.7566 59.0167 32.3298 58.8272L9.22988 48.5606C8.63401 48.2958 8.25 47.7048 8.25 47.0528Z"
                stroke="#490E00"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M20.625 12.375L44.3952 22.9395C44.9911 23.2043 45.375 23.7952 45.375 24.4473V34.375"
                stroke="#490E00"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="baggage-option">
              <p class="baggage-name">{{ item.name }}</p>
              <p class="baggage-detail">{{ item.explain }}</p>
              <p class="price">
                {{ item.shippingUnitFee | currency : "JPY" : "" }}円
              </p>
            </div>
            <p-inputNumber
              [format]="false"
              [min]="0"
              [readonly]="true"
              [(ngModel)]="item.quantity"
            ></p-inputNumber>
          </div>
        </div>
      </section>
    </div>
    <div class="regist-footer-pc">
      <div class="space"></div>
      <div class="price-pc">
        <p *ngIf="discountAvail" class="baggage-note">{{ "regist.note" | translate }}</p>
        <div class="total-price">
          <p class="lbl">{{ "regist.shippingFeeTotal" | translate }}</p>
          <p class="price">{{ data.shippingFeeTotal | currency : "JPY" : "" }}円</p>
        </div>
      </div>
    </div>

    <div class="end" *ngIf="param != 'update' ">
      <button (click)="upsertShipRequest()" pButton class="p-button-warning btn-confirm btn-custom">
        {{ "confirm.continue" | translate }}
      </button>
      <button (click)="backToInput()" pButton class="p-button-warning btn-confirm btn-custom">
        {{ "confirm.back" | translate }}
      </button>
    </div>

    <div class="end" *ngIf="param == 'update' ">
      <button *ngIf="data?.cancelFlag != 1 && data?.shippingAcceptedStatus != 1 && data?.payStatus != 0 " 
      (click)="backToInputForUpdate()" pButton class="p-button-warning btn-confirm btn-custom">
        {{ "confirm.edit" | translate }}
      </button>
      <button *ngIf="data?.cancelFlag != 1 && data?.shippingAcceptedStatus != 1" 
      (click)="onCancel()" pButton class="p-button-warning btn-confirm btn-custom">
        {{ "confirm.cancel" | translate }}
      </button>
      <button (click)="goToTop()" pButton class="p-button-warning btn-confirm btn-custom">
        {{ "confirm.top" | translate }}
      </button>
    </div>
  </section>
</div>

<!-- popup -->
<p-dialog [showHeader]="false" [modal]="true" [contentStyle]="{backgroundColor: '#EBE6C8', padding: '0'}" position="center" [(visible)]="dialogFlag" [style]="{width: '320px'}">
  <div class="close">
      <i class="pi pi-times" (click)="closeDialog()" style="font-size: 15px; padding: 8px;"></i>
  </div>
  <div class="alert">
    <p>{{'confirm.msg080' | translate}}</p>
  </div>
  <div class="bottom">
    <button (click)="closeDialog()" pButton class="p-button-warning btn-app">{{'regist.btnClose' | translate}}</button>
  </div>
</p-dialog>
