<div class="container">
  <section>
    <div class="title">
      <img src="/assets/img/warning-ic.png" alt="icon" width="55" />
      <h2 style="color: #a98633; font-size: 48px; margin-left: 10px">
        <b>Error!</b>
      </h2>
    </div>

    <div class="notice">
      <p class="text-center">
        {{ "popup.notPay.line1" | translate }}<br />
        {{ "popup.notPay.line2" | translate }}
      </p>
    </div>
    <div class="end">
      <button
        (click)="top()"
        pButton
        class="p-button-warning btn-confirm btn-custom"
      >
        {{ "popup.notPay.btn" | translate }}
      </button>
    </div>
  </section>
</div>
