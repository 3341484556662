import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/service/app.service';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent {
  lang: number = 0;

  constructor(private appService: AppService, private router: Router) {
  }

  ngOnInit(): void {
    this.lang = this.appService.selectedLang;

    this.appService.selectedLangListener.subscribe({
      next: (res) => {
        this.lang = this.appService.selectedLang;
      }
    })
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  goToTop() {
    this.router.navigate(['carry-reserve']);
  }

  joinText(text: Array<string>) {
    return text.length > 0 ? text?.join('') : '';
  }

  getFromIndex(text: Array<string>, index: number) {
    return text.length > 0 ? text[index] : '';
  }

  
}
