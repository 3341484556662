import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmComponent } from './screens/confirm/confirm.component';
import { MasterFrameComponent } from './screens/master-frame/master-frame.component';
import { PolicyComponent } from './screens/policy/policy.component';
import { PreviewComponent } from './screens/preview/preview.component';
import { RegistComponent } from './screens/regist/regist.component';
import { ThanksComponent } from './screens/thanks/thanks.component';
import { TopComponent } from './screens/top/top.component';
import { TransactionLawComponent } from './screens/transaction-law/transaction-law.component';
import { NoPaymentPopupComponent } from './screens/no-payment-popup/no-payment-popup.component';
import { WarningPopupComponent } from './screens/warning-popup/warning-popup.component';

const routes: Routes = [
  {
    path: '',
    component: MasterFrameComponent,
    children: [
      {
        path: 'carry-reserve/regist',
        component: RegistComponent
      },
      {
        path:'carry-reserve',
        component : TopComponent
      },
      {
        path:'carry-reserve/confirm',
        component:ConfirmComponent
      },
      {
        path:'carry-reserve/preview',
        component:PreviewComponent
      },
      {
        path:'carry-reserve/policy',
        component:PolicyComponent
      },
      {
        path:'carry-reserve/business_deal',
        component:TransactionLawComponent
      },
      {
        path:'carry-reserve/complete',
        component:ThanksComponent
      },
      {
        path:'carry-reserve/error',
        component:NoPaymentPopupComponent
      },
      {
        path:'carry-reserve/test',
        component:WarningPopupComponent
      },
      {
        path:'',
        redirectTo: 'carry-reserve', pathMatch: 'full' 
      }
    ]
  }
  // { path: '**',   redirectTo: 'carry-reserve', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
