import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-no-payment-popup',
  templateUrl: './no-payment-popup.component.html',
  styleUrls: ['./no-payment-popup.component.scss']
})
export class NoPaymentPopupComponent {

  constructor(private router: Router) {
  }

  ngOnInit(): void {

  }

  top() {
    this.router.navigate(['carry-reserve'])
  }
}
