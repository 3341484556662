export class Constant {
    public static HTTP_200 = 200;

    public static HTTP_500 = 500;

    public static REQUEST_LIMIT = 170;

    public static BACK_FROM_CONFIRM = 'backFromConfirm';

    public static UPDATE = 'update';
}