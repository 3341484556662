import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.scss']
})
export class WarningPopupComponent implements OnInit {
  stayFacilityName: string = '';
  tel = '';

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef) {
  }

  ngOnInit(): void {
    this.stayFacilityName = this.config.data?.stayFacilityName;
    this.tel = this.config.data?.tel;
  }

  close() {
    this.ref.close(false);
  }

  next() {
    this.ref.close(true);
  }
}
