<div class="container">
    <section>
      <div class="title">
        <img src="/assets/img/warning-ic.png" width="55" alt="icon">
        <b><h2>{{ "popup.warning.check" | translate }}</h2></b>
        <p class="description">
          {{ "popup.warning.note1" | translate }}<br>
          {{ "popup.warning.note2" | translate }}<br>
          {{ "popup.warning.note3" | translate }}<br>
        </p>
      </div>

      <div class="notice">
          <p class="text-center">{{ "popup.warning.hotel" | translate }}</p>
          <h2 class="text-center"><b>{{stayFacilityName}}</b></h2>
          <p class="text-center">{{ "popup.warning.phone" | translate }}</p>
          <h2 class="text-center" style="font-size: 36px;"><b>{{tel}}</b></h2>
          <p></p>
      </div>
      <div class="end">
        <button (click)="next()" pButton class="p-button-warning btn-confirm btn-custom btn-next">
            {{ "popup.warning.ok" | translate }}
          </button>
          <button (click)="close()" pButton class="p-button-warning btn-confirm btn-custom">
            {{ "popup.modify" | translate }}
          </button>
      </div>
    </section>
  </div>
  