export class MessageJp {
    static ERR_TITLE = "エラー";
    static SUCCESS_TITLE = "成功";
    static SERVER_ERRR = "サーバーエラー";

    static MSG_053 = "は、有効なメールアドレス形式で指定してください。";
    static MSG_056 = "は必須です。";
    static MSG_016 = "には、アルファベッドのみ使用できます";
    static MSG_080_A01 = "お手荷物の詳細が入力されていません。";
    static MSG_080_A03 = "ご利用日の受付上限に達しています。申し訳ありませんが予約をお受けすることができません。";
    static MSG_068 = "該当のデータがありません。"
    static MSG_073 = "この配送依頼をキャンセルしてよろしいですか？";

    static MSG_099 = "決済金額がオーバーしているため決済処理を終了します。\n初めから操作をやり直してください。";

    static OK = "OK";
    static CANCEL = "キャンセル";
}