import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[numberOnly]',
})
export class NumberOnlyDirective {
  constructor(private cn: NgControl) {}

  // @HostListener('input', ['$event']) onInputChange(event: InputEvent) {
  //   console.log('old data: ', this.cn.control?.value)
  //   console.log('new data: ', event?.data)
  //   const initialValue: string | null = event?.data == '' ? null : event?.data;
  //   if(initialValue == null) {
  //     return;
  //   }
  //   // console.log(initialValue);
  //   const sanitizedValue = initialValue.replace(/[^0-9０-９]/g, '');
  //   // console.log(sanitizedValue);
  //   // this.cn.control?.setValue(this.cn.control.value + sanitizedValue);
  // }

  @HostListener('blur', ['$event']) onBlur(event: Event) {
    const initialValue: string = this.cn.control?.value == '' ? null : this.cn.control?.value;
    if(initialValue == null) {
      return;
    }

    const sanitizedValue = initialValue.replace(/[^0-9０-９]/g, '');
    
    let validValue = this.convertFullwidthToHalfwidth(sanitizedValue);
    this.cn.control?.setValue(validValue);
  }

  convertFullwidthToHalfwidth(input: string) {
    return input.replace(/[０-９]/g, function(char) {
        var fullWidth = char.charCodeAt(0);
        var halfWidth = fullWidth - 65248;
        return String.fromCharCode(halfWidth);
    });
  }
}