<section class="container">
    <div class="content">
        <h3>{{ "law.txt1" | translate }}</h3>
        <p class="head">{{ "law.txt2" | translate }}</p>
        <table class="mobile">
            <tr>
                <th>{{ "law.txt3" | translate }}</th>
            </tr>
            <tr>
                <td>{{ "law.CORPORATION_NAME" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "law.txt5" | translate }}</th>
            </tr>
            <tr>
                <td>{{ "law.CORPORATION_CEO" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "law.txt7" | translate }}</th>
            </tr>
            <tr>
                <td>{{ "law.CORPORATION_ADDRESS" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "law.txt9" | translate }}</th>
            </tr>
            <tr>
                <td>{{ "law.CORPORATION_TEL" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "law.txt11" | translate }}</th>
            </tr>
            <tr>
                <td>{{ "law.CORPORATION_MAIL" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "law.txt13" | translate }}</th>
            </tr>
            <tr>
                <td>{{ "law.DOMAIN" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "law.txt15" | translate }}</th>
            </tr>
            <tr>
                <td>{{ "law.txt16" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "law.txt17" | translate }}</th>
            </tr>
            <tr>
                <td>{{ "law.txt18" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "law.txt19" | translate }}</th>
            </tr>
            <tr>
                <td>{{ "law.txt20" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "law.txt21" | translate }}</th>
            </tr>
            <tr>
                <td>{{ "law.txt22" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "law.txt23" | translate }}</th>
            </tr>
            <tr>
                <td>{{ "law.txt24" | translate }}</td>
            </tr>
        </table>
        <table class="pc">
            <tr>
                <th>{{ "law.txt3" | translate }}</th>
                <td>{{ "law.CORPORATION_NAME" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "law.txt5" | translate }}</th>
                <td>{{ "law.CORPORATION_CEO" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "law.txt7" | translate }}</th>
                <td>{{ "law.CORPORATION_ADDRESS" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "law.txt9" | translate }}</th>
                <td>{{ "law.CORPORATION_TEL" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "law.txt11" | translate }}</th>
                <td>{{ "law.CORPORATION_MAIL" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "law.txt13" | translate }}</th>
                <td>{{ "law.DOMAIN" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "law.txt15" | translate }}</th>
                <td>{{ "law.txt16" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "law.txt17" | translate }}</th>
                <td>{{ "law.txt18" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "law.txt19" | translate }}</th>
                <td>{{ "law.txt20" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "law.txt21" | translate }}</th>
                <td>{{ "law.txt22" | translate }}</td>
            </tr>
            <tr>
                <th>{{ "law.txt23" | translate }}</th>
                <td>{{ "law.txt24" | translate }}</td>
            </tr>
        </table>
        <div class="last">
            <button (click)="goToTop()" pButton class="p-button-warning btn-confirm btn-custom">
                {{ "preview.top" | translate }}
            </button>
        </div>
    </div>
</section>