import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TableModule } from "primeng/table";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { AvatarModule } from "primeng/avatar";
import { AvatarGroupModule } from "primeng/avatargroup";
import { CardModule } from "primeng/card";
import { PasswordModule } from "primeng/password";
import { InputTextModule } from "primeng/inputtext";
import { CheckboxModule } from "primeng/checkbox";
import { BadgeModule } from "primeng/badge";
import { DropdownModule } from "primeng/dropdown";
import { ButtonModule } from "primeng/button";
import { FileUploadModule } from "primeng/fileupload";
import { CommonModule } from "@angular/common";
import { DialogModule } from "primeng/dialog";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ToastModule } from "primeng/toast";
import { RadioButtonModule } from "primeng/radiobutton";
import { SidebarModule } from "primeng/sidebar";
import { AccordionModule } from "primeng/accordion";
import { FieldsetModule } from "primeng/fieldset";
import { PanelModule } from "primeng/panel";
import { InputTextareaModule } from "primeng/inputtextarea";
import { PaginatorModule } from "primeng/paginator";
import { DividerModule } from 'primeng/divider';
import {
    TranslateLoader,
    TranslateModule,
    TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CalendarModule } from "primeng/calendar";
import { InputNumberModule } from "primeng/inputnumber";
import { SliderModule } from 'primeng/slider';
// export function HttpLoaderFactory(http: HttpClient) {
//     return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
// }
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AvatarModule,
        AvatarGroupModule,
        CardModule,
        TableModule,
        PasswordModule,
        InputTextModule,
        CheckboxModule,
        BadgeModule,
        DropdownModule,
        ButtonModule,
        FileUploadModule,
        DialogModule,
        ConfirmDialogModule,
        ToastModule,
        RadioButtonModule,
        SidebarModule,
        AccordionModule,
        FieldsetModule,
        PanelModule,
        PaginatorModule,
        CalendarModule,
        SliderModule,
        // TranslateModule.forRoot({
        //     loader: {
        //         provide: TranslateLoader,
        //         useFactory: HttpLoaderFactory,
        //         deps: [HttpClient],
        //     },
        // }),
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AvatarModule,
        AvatarGroupModule,
        CardModule,
        TableModule,
        PasswordModule,
        InputTextModule,
        CheckboxModule,
        BadgeModule,
        DropdownModule,
        ButtonModule,
        FileUploadModule,
        DialogModule,
        AccordionModule,
        FieldsetModule,
        PanelModule,
        TranslateModule,
        InputTextareaModule,
        CalendarModule,
        InputNumberModule,
        DividerModule,
        SliderModule
    ],
    providers: [TranslateService, DynamicDialogConfig, DynamicDialogRef],
})
export class BaseWebModule { }
