<div class="container">
    <section>
      <div class="input-area">
        <div class="form-info">
          <div class="barcode">
            <p>{{ "preview.shippingNumber" | translate }}</p>
            <div>
              <svg id="barcode"></svg>
            </div>
          </div>
          <div class="item-info">
            <p>{{ "preview.hotelName" | translate }} &nbsp;:&nbsp;</p>
            <p class="content">{{data?.stayFacilityName}}</p>
          </div>
          <div class="item-info">
            <p>{{ "preview.hotelReservationName" | translate }} &nbsp;:&nbsp;</p>
            <p class="content">{{data?.reserverName}}</p>
          </div>
          <div class="item-info">
            <p>{{ "preview.dateOfUse" | translate }} &nbsp;:&nbsp;</p>
            <p class="content">{{data?.usageDate}}</p>
          </div>
          <div class="item-info">
            <p>{{ "preview.baggage" | translate }} &nbsp;:&nbsp;</p>
            <div class="baggage">
                <p *ngFor="let item of data?.shipDetail" class="item">{{item.shippingFeeName}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="notice">
        <p *ngIf="action != ''" style="white-space:pre-wrap">{{ "preview.message1" | translate }}</p>
        <p *ngIf="action == ''" style="white-space:pre-wrap">{{ "preview.message2" | translate }}</p>
        <div class="check-info">
          <div class="checkbox">
            <input id="cb1" type="checkbox" [(ngModel)]="checkbox1"> &nbsp;<label for="cb1">{{ "preview.checkbox13" | translate }}</label>
        </div>
          <div class="checkbox">
              <input id="cb2" type="checkbox" [(ngModel)]="checkbox2"> &nbsp;<label for="cb2">{{ "preview.checkbox14" | translate }}</label>
          </div>
        </div>
      </div>
      <div class="end">
        <button (click)="redirectGMOPayment()" *ngIf="action != ''" [disabled]="!checkbox1 || !checkbox2"  pButton class="p-button-warning btn-confirm btn-custom">
          {{ "preview.payment" | translate }}
        </button>
        <button (click)="goToTop()" [disabled]="!checkbox1 || !checkbox2" *ngIf="action == ''" pButton class="p-button-warning btn-confirm btn-custom">
            {{ "preview.top" | translate }}
        </button>
      </div>
    </section>
  </div>
  