import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ShippingFeeService {
  constructor(private http: HttpClient) {}

  getMasterShippingFee(date: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/user/master-shipping-fee`, {params: {usageDate: date}});
  }
}
