import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AppService } from '../service/app.service';



export const halfwidth = (appService: AppService): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value;

    if (value) {
      // Sử dụng biểu thức chính quy để kiểm tra ký tự halfwidth
      const halfwidthRegex = /^[!-~\s]*$/; // Biểu thức chính quy cho ký tự halfwidth

      if (!halfwidthRegex.test(value) && appService.selectedLang != 0) {
        return { halfwidth: true }; // Trả về lỗi nếu không phải là ký tự halfwidth
      }
    }

    return null; // Trả về null nếu không có lỗi
  };
}

export const selected = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value?.value ? null : { selected: true } ;
  };
}