import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as JsBarcode from 'jsbarcode';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Constant } from 'src/app/commons/Constants';
import { MessageEn } from 'src/app/commons/MessageEn';
import { MessageJp } from 'src/app/commons/MessageJp';
import { AppService } from 'src/app/service/app.service';
import { ShippingFeeService } from 'src/app/service/shipping-fee.service';
import { ShippingService } from 'src/app/service/shipping.service';
import { SpinnerService } from 'src/app/service/spinner.service';
import { PreviewComponent } from '../preview/preview.component';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  currentTotalShip: number = 0;
  
  actionFlag: number = 1

  pdFacility: Array<any> = [];

  responseLang: any[] = [];

  listBaggageWithLang: any[] = [];

  listBaggageResponse: any[] = [];

  baggageTotalPrice: number = 0;

  mes = MessageJp;

  currrentLang: number = 0;

  submitFlag: boolean = false;

  discountAvail: boolean = false;

  data: any;

  oldData: any;

  param: string = '';

  barcode: string = '';

  ref: DynamicDialogRef | undefined;

  constructor(
    private appService: AppService, 
    private router: Router,
    private route: ActivatedRoute,
    private shippingFeeService: ShippingFeeService,
    private shippingService: ShippingService,
    private confirmationService: ConfirmationService,
    private spinner: SpinnerService,
    private dialogService: DialogService,
    private messageService: MessageService) {
  }

  dialogFlag: boolean = false;

  ngOnInit(): void {
    this.handleLangChange();
    this.getParamScreen();
    this.getDataFormRegist();
    this.getTotalShip();

    this.getListBaggage();
    this.getDiscountAvail();
  }

  getDiscountAvail(): any {
    this.shippingService.getDiscountAvail().subscribe({
      next: (res: any) => {
        if (res.status === Constant.HTTP_200) {
          this.discountAvail = res.data;
        }
      },
      error: (err) => console.error(err),
    });
  }

  checkChangePrice(): boolean {
    if(this.oldData && this.data) {
      if(this.data.shippingFeeTotal != this.oldData.shippingFeeTotal) {
        return true;
      }
    }
    return false;
  }

  getParamScreen() {
    const paramObj: any = this.route.snapshot.queryParams;
    this.param = paramObj.param;
  }

  handleLangChange() {
    this.appService.selectedLangListener.subscribe({
      next: (res) => {
        this.appService.selectedLang == 0 ? (this.mes = MessageJp) : (this.mes = MessageEn);
        this.setListBaggage(this.appService.selectedLang);
      },
    });
  }

  getListBaggage() {
    let usageDate = (this.data?.usageDate as string).replaceAll('/', '-');
    this.shippingFeeService.getMasterShippingFee(usageDate).subscribe({
      next: (res) => {
        if (res.status === Constant.HTTP_200) {
          const oldId: number[] = this.listBaggageResponse.map((x) => x.id);
          const newId: number[] = (res.data as Array<any>).map((x) => x.id);
          if (!oldId.equals(newId)) {
            this.listBaggageResponse = res.data;
            this.setListBaggage(this.data.languageSelect);
          }
        }
      },
    });
  }

  setListBaggage(lang: number) {
    switch (lang) {
      case 0:
        this.mapBaggageToLang();
        break;
      case 1:
        this.mapBaggageToLang('En');
        break;
      case 2:
        this.mapBaggageToLang('Ko');
        break;
      case 3:
        this.mapBaggageToLang('Zhch');
        break;
      case 4:
        this.mapBaggageToLang('Zhtw');
        break;
    }
  }

  mapBaggageToLang(lang: string = '') {
    this.listBaggageWithLang = this.listBaggageResponse.map((item) => {
      return {
        id: item.id,
        name: item['shippingFeeName' + lang],
        explain: item['explanation' + lang],
        shippingFeeNormal: item.shippingFeeNormal,
        qty: item?.qty,
      };
    });

    this.listBaggageWithLang.forEach((item) => {
      (this.data.shipDetail as Array<any>).forEach((detail) => {
        if(detail.shippingFeeId == item.id) {
          detail.name = item.name;
          detail.explain = item.explain;
        }
      })
    })
  }

  getTotalShip() {
    this.shippingService.getTotalShip(this.data.usageDate).subscribe({
      next: (res) => {
        if(res.status == Constant.HTTP_200 && res.data) {
          this.currentTotalShip = res.data;
        }
      },
      error: (err) => console.log(err)
    })
  }

  getDataFormRegist() {
    this.data = this.appService.getRequest();
    const currentLang = parseInt(this.data?.languageSelect || 0);
    this.appService.selectedLangListener.next(currentLang);

    if(this.param == Constant.UPDATE) {
      this.appService.saveOldRequest(this.data);
    }
    this.oldData = this.appService.getOldRequest();

    if(this.data.id) {
      this.barcode = `${this.data.id}`.padStart(8, '0');
      JsBarcode("#barcode", this.barcode, {
        width:3,
        height:40,
        displayValue: true,
        textPosition: 'top',
        fontSize: 40,
        background: "#FFF9F0",
        lineColor: '#490E00'
      });
    }
  }

  upsertShipRequest() {
    if(!this.data?.id && this.currentTotalShip > Constant.REQUEST_LIMIT) {
      this.openDialog();
      return;
    }
    this.spinner.requestStarted();
    const req = {
      ...this.data,
      action: this.data?.id ? 'update' : 'regist',
      languageSelect: this.appService.selectedLang
    }
    this.shippingService.upsertShipRequest(req).subscribe({
      next: (res) => {
        if(res.status == Constant.HTTP_200) {
          this.messageService.add({ severity: 'success', summary: this.mes.SUCCESS_TITLE, detail: res.message});
          if(req.action === 'update') {
            this.checkSendMailNotChangePrice(res.data?.id, req.action);
          }
          else {
            this.previewResult(res.data?.id, req.action);
          }
        }
      },
      error: (err) => {
        this.messageService.add({ severity: 'error', summary: this.mes.ERR_TITLE, detail: this.mes.SERVER_ERRR});
      },
      complete: () => {
        this.spinner.resetSpinner();
        this.appService.clearAllData();
      }
    });
  }

  checkSendMailNotChangePrice(id : number, action: string) {
    // this.oldData = this.appService.getOldRequest();
    if(this.oldData && this.oldData.shippingFeeTotal == this.data.shippingFeeTotal) {
      // not change price
      this.previewResult(id, '');
      // send mail
      this.shippingService.sendMailUpdateNotChangePrice(this.data).subscribe({
        error: (err) => {
          console.log(err);
        },
        complete: () => {
          this.appService.clearOldRequest();
          this.spinner.resetSpinner();
        }
      });
    }
    else {
      // change price
      this.previewResult(id, action);
    }
  }

  onCancel() {
    this.confirmationService.confirm({
      message: this.mes.MSG_073,
      icon: 'pi pi-info-circle',
      acceptLabel: this.mes.OK,
      rejectLabel: this.mes.CANCEL,
      accept: () => {
          this.cancel();
      },
      reject: () => {
          return;
      }
    });
  }

  cancel() {
    this.spinner.requestStarted();
    const req = {
      ...this.data,
      action: 'cancel',
      languageSelect: this.appService.selectedLang
    }
    this.shippingService.upsertShipRequest(req).subscribe({
      next: (res) => {
        if(res.status == Constant.HTTP_200) {
          this.messageService.add({ severity: 'success', summary: this.mes.SUCCESS_TITLE, detail: res.message});
          this.router.navigate(['carry-reserve']);
        }
      },
      error: (err) => {
        this.messageService.add({ severity: 'error', summary: this.mes.ERR_TITLE, detail: this.mes.SERVER_ERRR});
      },
      complete: () => {
        this.spinner.resetSpinner();
        this.appService.clearAllData();
      }
    })
  }

  backToInput() {
    this.router.navigate(['carry-reserve/regist'], {queryParams: {param: Constant.BACK_FROM_CONFIRM}}).then(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }

  backToInputForUpdate() {
    this.router.navigate(['carry-reserve/regist'], {queryParams: {param: Constant.UPDATE}}).then(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }

  goToTop() {
    this.router.navigate(['carry-reserve']);
  }
  
  previewResult(id: number, action: string) {
    this.ref = this.dialogService.open(PreviewComponent, { 
      width: '95%',
      showHeader: false,
      position: "top",
      contentStyle: {
        padding: '0px',
      },
      data: {
          shipId: id,
          action: action
      },
    });

    this.ref.onClose.subscribe(() => {
      this.goToTop();
    });
  }

  openDialog() {
    this.dialogFlag = true;
  }

  closeDialog() {
    this.dialogFlag = false;
  }

}
