<div class="frame">
    <section class="header">
        <div class="title-left">
            <p>{{ 'header.hakone1' | translate }}{{ 'header.hakone2' | translate }}</p>
        </div>
        <div class="title-right">
            <p>{{ 'header.tozanbus' | translate }}</p>
            <app-language-dropdown [options]="pdLanguages"></app-language-dropdown>
        </div>
    </section>
    
    <section class="main">
        <router-outlet></router-outlet>
    </section>
    
    <section class="footer">
        <p>
            <a [routerLink]="'/carry-reserve/policy'">{{ 'footer.sitePolicy' | translate }} </a>
            &nbsp; &nbsp; <a [routerLink]="'/carry-reserve/business_deal'">{{ 'footer.description' | translate }} </a>
        </p>
        <p class="grow">
            <a href="https://www.odakyu-hakone.jp/privacy/" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path d="M4.75 2.25H2.25C1.55964 2.25 1 2.80964 1 3.5V9.75C1 10.4404 1.55964 11 2.25 11H8.5C9.19037 11 9.75 10.4404 9.75 9.75V7.25M7.25 1H11M11 1V4.75M11 1L4.75 7.25" stroke="#FFF9F0" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {{ 'footer.privacyPolicy' | translate }}
            </a>
        </p>
        <p>© Hakone Tozan Bus Co., Ltd.</p>
    </section>
</div>
<p-confirmDialog [closable]="false" acceptButtonStyleClass="p-button-warning" rejectButtonStyleClass="p-button-secondary"></p-confirmDialog>

