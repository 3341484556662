import { Component } from '@angular/core';

@Component({
  selector: 'app-master-frame',
  templateUrl: './master-frame.component.html',
  styleUrls: ['./master-frame.component.scss']
})
export class MasterFrameComponent {

  screenWidth = window.innerWidth;

  pdLanguages = [
    {value : 0, name: '日本語'},
    {value : 1, name: 'English'},
    {value : 2, name: '한국인'},
    {value : 3, name: '中文 (简体)'},
    {value : 4, name: '中文 (繁體)'}
  ]
}
