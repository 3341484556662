import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ShippingService {
  constructor(private http: HttpClient) {}

  getTotalShip(date: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/user/total-ship`, {params: {usageDate: date}});
  }

  upsertShipRequest(req: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/user/upsert-ship-request`, req, environment.httpOptions);
  }

  checkShipRequestExist(id: number, email: string): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/user/check-ship-exits`, {params: {id: id, email: email}});
  }

  getShippingRequest(id: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/user/shipping-request`, {params: {id: id}});
  }

  sendMailUpdateNotChangePrice(req: any) {
    return this.http.post(`${environment.apiUrl}/user/mailf003`, req, environment.httpOptions);
  }

  getShippingPreview(id: number) {
    return this.http.get(`${environment.apiUrl}/user/preview`, {params: {id: id}});
  }

  getTimeOver1130(usageDate: string){
    return this.http.get(`${environment.apiUrl}/user/get-time-over-1130`, {params: {usageDate: usageDate}});
  }

  getDiscountAvail(){
    return this.http.get(`${environment.apiUrl}/user/get-discount-avail`);
  }
}
