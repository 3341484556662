import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transaction-law',
  templateUrl: './transaction-law.component.html',
  styleUrls: ['./transaction-law.component.scss']
})
export class TransactionLawComponent {

  constructor(
    private router: Router) {
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  goToTop() {
    this.router.navigate(['carry-reserve']);
  }

}

