import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as JsBarcode from 'jsbarcode';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Constant } from 'src/app/commons/Constants';
import { MessageEn } from 'src/app/commons/MessageEn';
import { MessageJp } from 'src/app/commons/MessageJp';
import { AppService } from 'src/app/service/app.service';
import { GmoPaymentService } from 'src/app/service/gmo-payment.service';
import { ShippingService } from 'src/app/service/shipping.service';
import { SpinnerService } from 'src/app/service/spinner.service';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent implements OnInit {
  data: any;

  barcode: string = '';

  action: string = '';

  checkbox1: boolean = false;

  checkbox2: boolean = false;

  gmoRes: any;

  mes = MessageJp;

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private spinner: SpinnerService,
    private gmoService: GmoPaymentService,
    private messageService: MessageService,
    private router: Router,
    private appService: AppService,
    private shippingService: ShippingService) {
  }

  ngOnInit(): void {
    this.getDataPreview();
    this.handleLangChange();
  }

  goToTop() {
    this.router.navigate(['carry-reserve']);
    this.ref.close();
  }

  handleLangChange() {
    this.appService.selectedLangListener.subscribe({
      next: (res) => {
        this.appService.selectedLang == 0 ? (this.mes = MessageJp) : (this.mes = MessageEn);
      },
    });
  }


  getDataPreview() {
    this.spinner.requestStarted();

    const id = parseInt(this.config.data?.shipId);
    this.action = this.config.data?.action;
    console.log(this.action);
    this.shippingService.getShippingPreview(id).subscribe({
      next: (res: any) => {
        if(res.status == Constant.HTTP_200) {
          this.data = res.data;
          if(this.data.id) {
            this.barcode = `${this.data.id}`.padStart(8, '0');
            JsBarcode("#barcode", this.barcode, {
              width:3,
              height:30,
              displayValue: true,
              textPosition: 'top',
              fontSize: 40,
              background: "#FFF9F0",
              lineColor: '#490E00'
            });
          }
        }
      },
      complete: () => {
        this.spinner.resetSpinner();
        this.handleGMOPayment();
        document.getElementsByClassName('p-dialog-content')[0].scrollTo(0,0);
      }
    })
  }


  handleGMOPayment() {
    this.spinner.requestStarted();
    if(this.action === "regist") {
      this.gmoService.getNewPaymentUrl(this.data?.id).subscribe({
        next: (res) => {
          this.gmoRes = res;
        },
        complete: () => {
          this.spinner.resetSpinner();
        }
      });
    }
    if(this.action === "update") {
      const req = {
        shipId: this.data?.id
      }
      this.gmoService.repayment(req).subscribe({
        next: (res) => {
          this.gmoRes = res;
        },
        error: () => {this.messageService.add({ severity: 'error', summary: this.mes.ERR_TITLE, detail: this.mes.SERVER_ERRR })},
        complete: () => {
          this.spinner.resetSpinner();
        }
      });
    }
  }

  redirectGMOPayment() {
    if(this.gmoRes.status == Constant.HTTP_200) {
      window.location.href = this.gmoRes.data;
    }
    if(this.gmoRes.status == Constant.HTTP_500 && this.gmoRes.data == 99) {
      this.messageService.add({ severity: 'error', summary: this.mes.ERR_TITLE, detail: this.mes.MSG_099 })
      this.goToTop();
    }
    if(this.gmoRes.status == Constant.HTTP_500) {
      this.messageService.add({ severity: 'error', summary: this.mes.ERR_TITLE, detail: this.gmoRes.message })
      this.goToTop();
    }
  }
}
